import React from 'react'
import { Layout } from '../components/layout/Layout'
import { SiteHeader } from '../components/site-header/SiteHeader'
import { SiteFooter } from '../components/site-footer/SiteFooter'
import { TextBanner } from '../components/text-banner/TextBanner'
import { HeadingMd } from '../components/heading/Heading'
import { Cards } from '../components/cards/Cards'

const MondhygienistePage = () => {
  return (
    <Layout title="De Mondhygiëniste" desc="Goede mondhygiëne is erg belangrijk voor een mooi en gezond gebit, regelmatig bezoek aan de mondhygiënist draagt hieraan bij. De mondhygiënist richt zich op preventie en helpt
    bij het voorkomen van tandbederf en tandvleesproblemen.">
      <SiteHeader />

      <TextBanner>
        <HeadingMd h={2}>Over mij</HeadingMd>
        <p>
          Afgestudeerd (bachelor-mondzorgkunde) in 1993 aan de Hogeschool van Utrecht. Sindsdien bij diverse
          tandartsenpraktijken gewerkt en in 2004 besloten een eigen praktijk op te richten. Ik ben lid van de
          Nederlandse Vereniging van Mondhygiënisten, (NVM) en BIG geregistreerd. De reden voor het oprichten van een
          eigen praktijk zijn mijn persoonlijke ambities om meer tijd en aandacht te besteden aan mondzorg en daarmee
          cliënten centraal te stellen.
        </p>
      </TextBanner>

      <Cards />

      <SiteFooter />
    </Layout>
  )
}

export default MondhygienistePage
