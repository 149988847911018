import React from 'react'
import { HeadingSm } from '../heading/Heading'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import * as styles from './Cards.module.css'

export function Cards() {
  return (
    <section className={styles.cards}>
      <div className={styles.layout}>
        <Card
          image={
            <StaticImage
              className={styles.image}
              src="../../images/tools-for-tooth-care.jpg"
              alt="Veelgestelde vragen"
            />
          }
          title="Veelgestelde vragen"
          url="/veelgestelde-vragen"
        />
        <Card
          image={
            <StaticImage
              className={styles.image}
              src="../../images/pexels-andrea-piacquadio-3779713.jpg"
              alt=""
            />
          }
          title="Behandelingen"
          url="/behandelingen"
        />
        <Card
          image={
            <StaticImage
              className={styles.image}
              src="../../images/dental-office-light.jpg"
              alt=""
            />
          }
          title="Over de mondhygiëniste"
          url="/mondhygieniste"
        />
      </div>
    </section>
  )
}

function Card({ image, title, url }) {
  return (
    <div to={url} className={styles.card}>
      <div className={styles.image}>{image}</div>
      <div className={styles.heading}>
        <HeadingSm>{title}</HeadingSm>
      </div>
      <div className={styles.link}>
        <Link to={url}></Link>
      </div>
    </div>
  )
}
