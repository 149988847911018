import React from "react"
import { Wysiwyg } from '../wysiwyg/Wysiwyg'
import { ContainerMd } from '../container/Container'
import * as styles from './TextBanner.module.css'

export function TextBanner ({ children, buttons }) {
  return (
    <div className={styles.textBanner}>
      <ContainerMd>
        <div className={styles.content}>
          <Wysiwyg>
            { children }
          </Wysiwyg>
        </div>
      </ContainerMd>
    </div>
  )
}
